import React from "react";
import { Redirect, Route } from "react-router-dom";
import { isAuthenticated } from "../utils/auth";

export const AuthRoute = ({ component, ...Rest }: any) => (
  <Route
    {...Rest}
    render={(props) =>
      isAuthenticated() ? (
        React.createElement(component, props)
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);
