import React, { Suspense } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { AuthRoute } from './auth.routes'

const Login = React.lazy(() => import('../screens/Login'))

const Orgs = React.lazy(() => import('../screens/Orgs'))
const Courses = React.lazy(() => import('../screens/Cursos'))
const Authors = React.lazy(() => import('../screens/Autores'))
const OrgDetails = React.lazy(() => import('../screens/OrgDetails'))
const CourseDetails = React.lazy(() => import('../screens/CourseDetails'))

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<></>}>
        <Switch>
          <Route path="/login" exact component={Login} />

          <AuthRoute path="/orgs/details/:uuid" component={OrgDetails} />
          <AuthRoute path="/courses/details/:uuid" component={CourseDetails} />
          <AuthRoute path="/orgs" component={Orgs} />
          <AuthRoute path="/courses" component={Courses} />
          <AuthRoute path="/authors" component={Authors} />

          <Route path="*" render={() => <Redirect to="/login" />} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}

export default Routes
