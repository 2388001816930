import React from "react";
import { useTransition } from "react-spring";

import Toast from "./Toast";

import { ToastMassege } from "../../hooks/toast";
import { Container } from "./styles";

interface ToastContainerProps {
  messages: ToastMassege[];
}

const ToastContainer: React.FC<ToastContainerProps> = ({ messages }) => {
  const messagesWithTransitions = useTransition(
    messages,
    (message: any) => message.id,
    {
      from: { right: "-120%", opacity: 0 },
      enter: { right: "0%", opacity: 1 },
      leave: { right: "-120%", opacity: 0 },
    } as any
  );

  return (
    <Container>
      {messagesWithTransitions.map(
        ({
          //@ts-ignore
          item,
          key,
          props,
        }) => (
          <Toast key={key} message={item} style={props}></Toast>
        )
      )}
    </Container>
  );
};

export default ToastContainer;
